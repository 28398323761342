<template>
    <v-autocomplete
        class="background--white"
        outlined
        v-bind="$attrs"
        :value="value"
        @change="onChange"
    >
        <template v-slot:selection="{ item, index }">
            <div class="chip-container">
                <v-chip v-if="index <= maxChipsDisplayed - 1" color="gray" small>
                    <v-icon v-if="deletableChips" @click.stop="deleteItem(index)" left small>
                        mdi-close-circle
                    </v-icon>
                    <span v-if="chipLabel">{{ item[chipLabel] }}</span>
                    <span v-else>{{ item.text || item }}</span>
                </v-chip>

                <span v-if="index === maxChipsDisplayed" class="grey--text text-caption">
                    {{ hiddenItemsLabel }}
                </span>
            </div>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    inheritAttrs: false,

    name: 'TextSelectPicker',

    props: {
        value: {
            type: Array,
            required: true,
        },
        chipLabel: {
            type: String,
            required: false,
        },
        deletableChips: {
            type: Boolean,
            required: false,
            default: false,
        },
        maxChipsDisplayed: {
            type: Number,
            required: false,
            default: 1,
        },
    },

    computed: {
        hiddenItemsLabel() {
            return this.value.length - this.maxChipsDisplayed === 1
                ? '(+1 autre)'
                : `(+${this.value.length - this.maxChipsDisplayed} autres)`;
        },
    },

    methods: {
        onChange(value) {
            this.$emit('input', value);
        },

        deleteItem(index) {
            const newVal = this.value;
            newVal.splice(index, 1);
            this.onChange(newVal);
        },
    },
};
</script>

<style lang="scss" scoped>
.chip-container {
    max-width: 100%;
}
</style>
