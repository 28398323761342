<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <div class="text-center">
                <v-icon :color="statusElements.color" v-on="on">
                    {{ statusElements.icon }}
                </v-icon>
            </div>
        </template>
        <span>{{ statusElements.text }}</span>
    </v-tooltip>
</template>

<script>
import { STATUT_INDISPO, STATUT_INDISPO_TOOLTIP_LABEL } from '@/constants/unavailabilities';

export default {
    name: 'StatusCellSection',

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    computed: {
        statusElements() {
            return this.getStatusElements(this.item.statut);
        },
    },

    methods: {
        getStatusElements(statut) {
            if (statut === STATUT_INDISPO.ANNULEE) {
                return {
                    color: 'warning',
                    icon: 'mdi-close-circle-outline',
                    text: STATUT_INDISPO_TOOLTIP_LABEL.ANNULEE,
                };
            }
            if (statut === STATUT_INDISPO.CREE) {
                return {
                    color: 'success',
                    icon: 'mdi-check-circle-outline',
                    text: STATUT_INDISPO_TOOLTIP_LABEL.CREE,
                };
            }
            if (statut === STATUT_INDISPO.AVALIDER) {
                return {
                    color: 'gray',
                    icon: 'mdi-progress-check',
                    text: STATUT_INDISPO_TOOLTIP_LABEL.AVALIDER,
                };
            }
            if (statut === STATUT_INDISPO.ATTENTEANNULATION) {
                return {
                    color: 'gray',
                    icon: 'mdi-progress-close',
                    text: STATUT_INDISPO_TOOLTIP_LABEL.ATTENTEANNULATION,
                };
            }
            if (statut === STATUT_INDISPO.REFUSEE) {
                return {
                    color: 'error',
                    icon: 'mdi-minus-circle-outline',
                    text: STATUT_INDISPO_TOOLTIP_LABEL.REFUSEE,
                };
            }
            if (statut === STATUT_INDISPO.VALIDEE) {
                return {
                    color: 'success',
                    icon: 'mdi-check-circle-outline',
                    text: STATUT_INDISPO_TOOLTIP_LABEL.VALIDEE,
                };
            }
            return undefined;
        },
    },
};
</script>
